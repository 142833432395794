import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Map as BaseMap, TileLayer, ZoomControl } from 'react-leaflet';

import { useConfigureLeaflet, useMapServices, useRefEffect } from 'hooks';
import { isDomAvailable } from 'lib/util';

// import 'assets/stylesheets/components/_map.scss';

const mapStyle = {
  height: '100%',
  position: 'relative',
  minHeight: '380px',
  Zindex:'-1'
}

const baseMapStyle = {
  width: '100%',
  height: '100%',
  minHeight: '380px',
  position: 'relative',
  backgroundColor: 'darkgray',
  Zindex:'-1'

}

const mapLoadingStyle = {
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  color: 'blue',
  width: '50%',
  height: '4em',
  lineHeight: '4em',
  textAlign: 'center',
  margin: 'auto'
}



const DEFAULT_MAP_SERVICE = 'OpenStreetMap';

const Map = ( props ) => {
  const { children, className, defaultBaseMap = DEFAULT_MAP_SERVICE, mapEffect, ...rest } = props;

  const mapRef = useRef();

  useConfigureLeaflet();

  useRefEffect({
    ref: mapRef,
    effect: mapEffect,
  });

  const services = useMapServices({
    names: [...new Set([defaultBaseMap, DEFAULT_MAP_SERVICE])],
  });
  const basemap = services.find(( service ) => service.name === defaultBaseMap );

  let mapClassName = `map`;

  if ( className ) {
    mapClassName = `${mapClassName} ${className}`;
  }

  if ( !isDomAvailable()) {
    return (
      // <div className={mapClassName}>
      <div style={mapStyle}>
      {console.log('loading map no DOM')}
        {/* <p className="map-loading">Loading map...</p> */}
        <p style={mapLoadingStyle}>Loading map...</p>
      </div>
    );
  }

  const mapSettings = {
    // className: 'map-base',
    zoomControl: false,
    doubleClickZoom: false,
    dragging: false,
    scrollWheelZoom: false,
    touchZoom: false,
    ...rest,
  };
  // const mapSettings = {
  //   // className: 'map-base',
  //   zoomControl: false, 
  //   doubleClickZoom: false, 
  //   closePopupOnClick: false, 
  //   dragging: false, 
  //   zoomSnap: false, 
  //   trackResize: false,
  //   touchZoom: false,
  //   scrollWheelZoom: false,
  //   ...rest,
  // };
  // zoomDelta: false, 


  return (
    // <div className={mapClassName}>
    <div style={mapStyle}>
      {console.log('loading map with DOM')}
      <BaseMap ref={mapRef}  style= {baseMapStyle} {...mapSettings}>
        { children }
        { basemap && <TileLayer {...basemap} /> }
        <ZoomControl position="bottomleft" />
      </BaseMap>
    </div>
  );
};

Map.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultBaseMap: PropTypes.string,
  mapEffect: PropTypes.func,
};

export default Map;
