import React from "react"
import { Marker } from 'react-leaflet';


const MyMarker = props => {

    const initMarker = ref => {
      if (ref) {
        if(ref.props.index == ref.props.selected){
            if(props.sign){
                ref.leafletElement.openPopup()
            }
        }else{
            if(props.sign){
                ref.leafletElement.closePopup()
            }
        }
      }
    }
  
    return <Marker ref={initMarker} {...props}/>
  }

export default MyMarker