import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { TextContainer } from './common'
// import { useImagesPhilosophy } from "../hooks/use-images-philosophy"
// import Img from "gatsby-image"


// import { Link } from "gatsby"



const Bg = styled.div`
   width: 100%;
   height: 100%;
   transition: 1s;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 10;
   
   ${p => p.open ? css`
      visibility: visible;
      background-color: rgba(0,0,0,0.6);     
  ` : css`
      background-color: rgba(0,0,0,0);
      visibility: hidden;
  `}
`

const Nl_window_big = styled.div`
    width: 80%;
    min-width: 370px;
    height: 80%;
    min-height: 420px;
    margin: auto;
    background-color: white;
    margin-top: 5%;
    padding: 1rem;

    @media (max-width: 600px) {
        min-width: 300px;
        width: 90%;
        min-height: 320px;
        height: 60%;
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

    }

    ${p => p.open ? css`
      visibility: visible;
  ` : css`
      visibility: hidden;
  `}

`

const Nl_window = styled.div`
    width: 100%;
    min-width: 350px;
    height: 100%;
    min-height: 400px;
    margin: auto;
    background-color: white;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 2px solid #214c9b;
    position: relative;


    h1{
        text-align: center;
        margin-top: 0;
    }
    p{
        font-size: 1.6rem;
    }

    @media (max-width: 600px) {
        min-width: 280px;
        min-height: 300px;
        p{
            font-size: 1.4rem;
        }
    }

`
const CloseW = styled.div`
    float: right;
    position: relative;
    cursor: pointer;
    width: 48px;
    height: 48px;
    margin-right: -1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    ::before,
    ::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #214C9B;
    }
    ::before {
        transform: rotate(45deg);
    }
    ::after {
        transform: rotate(-45deg);
    }
`
 
const FormContainer = styled.div`
   
    width:  90%;
    color: #214c9b;
    margin: auto;

    input{
        @media (max-width: 700px) {
            font-size: 1.2rem;
            
        }
        @media (max-width: 600px) {
            width:  90%;
        }
    }  
`
const TextInput = styled.input`
    display: block;
    margin: auto; 
    border: 1px solid black; 
    color: black;
    outline: 0;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    padding: 0.6rem 1.2rem;
    width: 500px;
    @media (max-width: 700px) {
        width: 320px;
    }
`
// width: max-content;
const SubmitInput = styled.input`
    margin: auto;
    background-color: transparent;
    color: #666;
    padding: 3px  30px 3px 30px; 
    border: 2px solid #0099df;
    cursor: pointer;
    display: block;
    font-size: 1.3rem;

    width: 18rem;
    transition: 0.3s;

    :hover{
        border-radius: 25px;
    }

    @media (max-width: 600px) {
        border-radius: 5px;
    }
`

let timer
let done = false
let Images =  {}
var useremail = ""



const NlSign = (props) =>{
    const [open, setOpen] = useState(props.open);

    const handleUpdate = (event) => {
        if(event.target.name == "email"){
            useremail = event.target.value
        }
    }

    const handleSubmit = async event => {
        await event.preventDefault()
        if(useremail != ''){
            await fetch(`https://feodorofflaw-api.herokuapp.com/api/v1/email_subscribe`,
            {method: "POST", mode: "cors", cache: "no-cache", credentials: "omit", headers: {"Content-Type": "application/json"}, redirect: "follow", referrer: "client", body: JSON.stringify({'email': useremail})})
            .then(res => res.json())
            .then(json => {
                // console.log("this is my response: ",json)
                // alert('Congratulations you are now subscribed!')
                alert(json.message)
                setOpen(false)
            });
        }
        
    }

    useEffect(() => {
        if(props.delay && !done){
            clearTimeout(timer);
            timer = setTimeout(() => {done=true;setOpen(true)}, props.delay*1000);
        }
       
    });
    // Images = useImagesPhilosophy()


    return(
    
        <Bg open={open}>
            <Nl_window_big open={open}>
                <Nl_window>
                    <CloseW onClick={()=>setOpen(false)}/>
                    <TextContainer style={{width:'100%'}}>
                        <h1>Newsletter</h1>
                        <p style={{margin:'auto', maxWidth:'80%', textAlign:'center'}}>Join our free biweekly newsletter!</p>
                        <br></br>
                        <p style={{margin:'auto', maxWidth:'80%', textAlign:'center'}}> Stay informed of the latest changes in immigration law, find out more about the exciting work our firm does, and be the first to learn about special promotions!</p>
                        <FormContainer> 
                            <form onSubmit={
                                (evt)=>{
                                    evt.preventDefault();
                                    evt.target.reset();
                                    (async () => { 
                                        await handleSubmit(evt)
                                    }) ();
                                }} style={{width:'100%', color:'#FDE3E3',marginTop:'2rem'}}>
                            <TextInput type="text" name="email" placeholder='your email' onChange={handleUpdate}/>
                            <SubmitInput type="submit" value="Join"/>
                            </form>
                        </FormContainer>
                    </TextContainer>
                </Nl_window>
            </Nl_window_big>
        </Bg>   
       
    )
}

export default NlSign
