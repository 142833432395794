import { useStaticQuery, graphql } from "gatsby"
export const useImagesPhilosophy = () => {
  const allImages = useStaticQuery(
    graphql`
      query imagesPhilosophy {
        bg1: file(relativePath: { eq: "images/philosophy/bg1.jpeg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 3200) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        bg2: file(relativePath: { eq: "images/philosophy/Panoramic.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 6000) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        close: file(relativePath: { eq: "images/philosophy/close.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 32) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        justice: file(relativePath: { eq: "images/who_we_are/justitia_statue.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1480) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        usa_map: file(relativePath: { eq: "images/who_we_are/usa_map.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 3200) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        logo_bg: file(relativePath: { eq: "images/who_we_are/logobg.jpeg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 3200) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        skyline_bg: file(relativePath: { eq: "images/who_we_are/skyline_bg.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 3200) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        nikolay_bg: file(relativePath: { eq: "images/who_we_are/nikolay_bg.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 938) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        nikolay_profile: file(relativePath: { eq: "images/who_we_are/nikolay_profile.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 938) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        side_view: file(relativePath: { eq: "images/philosophy/sideView.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 6000) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        bridge: file(relativePath: { eq: "images/philosophy/brooklyn_bridge.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 6000) {
                ...GatsbyImageSharpFluid
                # ...GatsbyImageSharpFluid_withWebp
                # ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )
  return allImages
}